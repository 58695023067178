export const environment = {
  production: true,
  API_BASE_URL: 'https://app.staging.footballer.zone/api/v0',
  WEB_SOCKET_URL: 'wss://app.staging.footballer.zone/api/v0',
  HOME_BASE_URL: 'https://app.staging.footballer.zone/',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  APP_INITIALIZED: 'mobAppInitialized',
  KEY: 'appPublicKey',
  PUSH_ACCESS_TOKEN: 'pushAccessToken',
  OFF_LINE: 'offLine',
  INIT_CODE_REQUESTED: 'initCodeRequested',
  DEBUG: 'debug',
  PROFILE: 'player',
  apiKey: 'AIzaSyCm0FpVP1p31toikYDlo6f5j3nanoC63AI',
  freeGeoIpApiUrl: 'https://freegeoip.net/json/',
  ipInfoApiUrl: 'https://ipinfo.io/geo?token=45b42d45cff351',
  whiteListDomains: ['app.staging.footballer.zone'],
  DEFAULT_AVATAR_URL: 'assets/images/player-icon-002.png'
};

export const auth0LockConfig = {
  clientID: 'urisNHb4VRTH0W0xjsdcvWQvXpBdZT9d',
  domain: 'mangonel.eu.auth0.com',
  primaryWidgetColor: '#C0C0C0',
  appTitle: 'Footballer',
  appLogo: '../../assets/images/soccer-icon-1.png'
};
