<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
><p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div *ngIf="!loading" class="container" role="main">

    <header class="row my-3 my-md-4 mb-md-5 align-items-center">
        <a href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
        <div class="col">
          <h2 class="m-0"><span class="animated"></span>{{pageTitle}} history</h2>
        </div>

        <div class="header-divider"></div>
    </header>

    <div class=" mx-auto">
      <div *ngFor="let event of events; index as i; even as isEven; odd as isOdd" class="border-bottom border-bottom-not-last py-2 item-{{i}} item-{{isOdd ? 'odd': 'even'}} {{event.viewedByPlayer || event.viewedByManager ? 'd-flex flex-column flex-sm-row' : ''}}">    <!--
        //
        TODO this section is duplicated between list and history view. We should move it to separate component and import in both places
        //
    -->
      <div class="{{event.viewedByPlayer || event.viewedByManager ? 'flex-fill' : ''}} mb-2">
        <div class="row mr-0">
          <div class="col d-inline-flex align-items-center" >
            <!-- <strong>{{event.active ? '⚽' : '⌛'}} {{event.name}}</strong> -->
            <a (click)="viewEvent(event)" href="javascript:">
              <i class="text-secondary {{event.active ? 'fas fa-futbol text-dark' : 'fas fa-hourglass-half'}} mr-2" title="{{event.active ? 'Active' : 'Pending'} Event"></i>
              <strong>{{event.name}}</strong>
            </a>
            <a href={{navigateHref}} target="_blank" (click)="openMap(event);" class="no-underline ml-3 text-info p-2" title="View on map"><i class="fas fa-map-marker-alt"></i><span class="sr-only">See on Map</span></a>
          </div>
          <div class="col-auto d-inline-flex align-items-center px-3" *ngIf="!event.active && event.whiteScore && event.blackScore" [ngSwitch]="event.wonLostDrewNothing">
            <i *ngSwitchCase="'won'" class="fas fa-trophy text-warning" title="You won this one!! :)"></i>
            <i *ngSwitchCase="'lost'" class="fas fa-poop text-primary" title="You lost this one!! :("></i>
            <i *ngSwitchCase="'drew'" class="far fa-grin-tongue-wink text-secondary" title="You drew this one!! :|"></i>
            <i *ngSwitchCase="'nothing'" class="fas fa-minus text-secondary" title="You did not play this one"></i>
            <i *ngSwitchDefault></i>
             <span class="inline-block mx-2">{{event.whiteScore + ':' + event.blackScore}}</span>

          </div>
        </div>

        <div class="text-sm">
          <div class="row">
            <div class="col">
              Manager: <span class="font-weight-500">{{event.manager.name}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col">
              {{event.nextOccurrenceTimestamp | date:'EEEE, MMM d yyyy @ HH:mm'}}h
            </div>
            <div class="col-auto">
              <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
              <ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
              ><p style="font-size: 20px; color: white">Loading...</p>
              </ngx-spinner>
            </div>
          </div>

          <div *ngIf="event.active && (event.viewedByPlayer || event.viewedByManager)" class="row">
            <div class="col">
              Confirmed by <strong>{{event.confirmedCount}}/{{event.maxPlayers}}</strong> players
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="event.viewedByPlayer || event.viewedByManager" class="d-flex flex-md-nowrap mb-2">
        <div class="row">
          <!-- Players -->
          <div class="col-auto">
            <button (click)="eventPlayers(event)" class="btn btn-sm btn-warning my-1">Details</button>
          </div>

          <!-- Details -->
          <!-- <div class="col-auto pl-0">
            <button (click)="viewEvent(event)" class="btn btn-sm btn-primary my-1">Details</button>
          </div> -->
        </div>
      </div>
    </div>

      <br/>
      <br/>
    <div *ngIf="events.length > 20" class="row">
      <div class="col">
        <button type="button" class="btn btn-secondary btn-sm px-3 text-white" (click)="loadMore()">More</button>
      </div>
    </div>

  </div><!-- /.col-lg-10 -->

</div>
