import {Component, OnInit} from "@angular/core";
import {PlayerService} from "../../shared/services/player.service";
import {Observable} from "rxjs";
import {Player} from "../../shared/models/player";
import {ActivatedRoute, Router} from "@angular/router";
import {Chat, ChatEntityType} from "../../shared/models/chat";
import {ChatService} from "../../shared/services/chat.service";
import {Message} from "../../shared/models/message";
import {EventService} from "../../shared/services/event.service";
import {Location} from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, mergeMap } from "rxjs/operators";

@Component({
  selector: 'chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['../chat.component.css'],
  providers: []
})
export class ChatDetailComponent implements OnInit {

  public chat: Chat;

  public loading = false;
  public errorMsg: string;

  public autocompletePlayer: string;
  public selectedPlayerHolder: Player;
  public typeaheadPlayerLoading: boolean;
  public typeaheadPlayerNoResults: boolean;
  public playerSelected: boolean;
  public playerDataSource: Observable<any>;

  public showDeleteDialog: boolean = false;

  private chatOld: Chat;
  private currentPlayer: Player;

  private chatEntityType = new ChatEntityType();

  constructor(public playerService: PlayerService,
              public chatService: ChatService,
              public route: ActivatedRoute,
              public eventService: EventService,
              public router: Router,
              private _location: Location,
              private spinner: NgxSpinnerService) {

    this.playerDataSource = new Observable((observer: any) => {
      observer.next(this.autocompletePlayer);
      this.loading = false;
      this.spinner.hide();
    })
    .pipe(
      debounceTime(500),
      mergeMap((token: string) => this.getPlayersAsObservable(token))
    );
  }

  ngOnInit() {
    window.scroll(0, 0);

    let me = this;
    me.loading = true;
    me.spinner.show();

    me.currentPlayer = me.playerService.getCurrentPlayer();

    let newChat = me.route.snapshot.params['newChat'];
    if (newChat) {
      me.chat = me.createNewChat();
    } else if (!localStorage.getItem('chat')) {
      me.router.navigate(['/chat-list']);

      return;
    } else {
      me.chat = JSON.parse(localStorage.getItem('chat'));
      me.chatOld = JSON.parse(JSON.stringify(me.chat));

      let superAdmin = this.currentPlayer.superAdmin;
      if (me.currentPlayer.id === me.chat.contact.id || superAdmin) {
        me.chat.viewedByManager = true;
      } else {
        me.chat.viewedByManager = false;
        this.spinner.hide();
        me.loading = false;

        return;
      }

      this.chatService.getPlayers(this.chat.id).subscribe(players => {
        this.chat.players = players;
      }, error => {
        console.log(error);
        this.errorMsg = error.error;

        this.chat.players = Array<Player>();
      });

      this.changeDisplayName();
    }
    this.spinner.hide();
    me.loading = false;
  }

  private changeDisplayName() : void{
    this.chatService.calculateDisplayName(this.chat, this.currentPlayer.id);
  }

  public saveChat(): void {
    if (this.chat.id) {
      this.chatService.updateChat(this.chat).subscribe(savedChat => {
        this.chat = savedChat;
        this.chat.viewedByManager = true;

        this.router.navigate(['/chat-list']);
      }, error => {
        console.log(error);
        this.errorMsg = error.error;
      });
    } else {
      this.chatService.createChat(this.chat).subscribe(savedChat => {
        this.chat = savedChat;
        this.chat.viewedByManager = true;

        this.router.navigate(['/chat-list']);
      }, error => {
        console.log(error);
        this.errorMsg = error.error;
      });
    }
  }

  public cancelChatDetailsChange(): void {
    if (this.chat.id) {
      this.chat = JSON.parse(JSON.stringify(this.chatOld));
      this.router.navigate(['/messages', {chatId: this.chat.id}]);
    } else {
      this.router.navigate(['/chat-list']);
    }
  }

  public addPlayerToChat(newPlayer: Player) {
    let currentPlayersOnChat = this.chat.players;
    let playerFound: boolean = false;

    for (let player of currentPlayersOnChat) {
      if (player.id === newPlayer.id) {
        playerFound = true;
        break;
      }
    }

    if (!playerFound) {
      this.chat.players.push(newPlayer);
    }
  }

  public removePlayerFromChat(player: Player) {
    let index = 0;
    for (let pl of this.chat.players) {
      if (player.id === pl.id) {
        this.chat.players.splice(index, 1);
        break;
      }

      index++;
    }
  }

  public changeTypeaheadLoading(e: boolean, fieldName: string): void {
    this.playerSelected = false;
    this.typeaheadPlayerLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean, fieldName: string): void {
    this.playerSelected = false;
    this.typeaheadPlayerNoResults = e;
  }

  public typeaheadPlayerSelected(selectedObject: any): void {
    this.playerSelected = true;

    this.addPlayerToChat(selectedObject.item);

    this.autocompletePlayer = null;
    this.typeaheadPlayerNoResults = false;
    this.selectedPlayerHolder = null;
  }

  public getPlayersAsObservable(partialPlayerNickName: string): Observable<any> {
    return this.playerService.getPlayers(partialPlayerNickName);
  }

  public removeChat(): void {
    this.spinner.show();
    this.loading = true;

    this.chatService.removeChat(this.chat.id).subscribe(() => {
      this.router.navigate(['/chat-list']);
      this.spinner.hide();
      this.loading = false;
    }, res => {
      this.router.navigate(['/chat-list']);
      console.log(res.error);
      this.spinner.hide();
      this.loading = false;
    });
  }

  private createNewChat(): Chat {
    let chat = new Chat();
    chat.contact = this.currentPlayer;
    chat.name = this.currentPlayer.name + ' - chat';
    chat.entityId = this.currentPlayer.id;
    chat.entityType = this.chatEntityType.PLAYER;
    chat.entityName = this.currentPlayer.name;
    chat.mutedPlayers = Array<string>();
    chat.text = '';
    chat.viewedByManager = true;
    chat.messages = Array<Message>();
    chat.players = Array<Player>();

    return chat;
  }
  public goBack():void{
    //console.log('uh');
    this._location.back();
  }
}
