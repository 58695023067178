<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
              ><p style="font-size: 20px; color: white">Loading...</p>
              </ngx-spinner>
<div *ngIf="!loading" class="container" role="main">

  <header class="row my-2 align-items-center">
    <a *ngIf="!showHelp" href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
    <a *ngIf="showHelp" href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="showHelp = false"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>

    <div class="col">
      <h2 class="mb-0">{{event.name}}</h2>
    </div>

    <!-- Rulebook
    <div class="event-list-button btn-lg">
      <a [routerLink]="['/something-rulebook-like-to-be-created', {event: event}]">
        <img src="../assets/images/rulebook.png" style="width: 40px; height: 40px" alt="Rulebook">
      </a>
    </div>
    -->

    <nav class="navbar sticky sticky-top navbar-main px-sm-0 animated fadeIn navbar-expand">
      <div class="container px-0 px-sm-3">
        <div style="padding-right: 5px;">
          <a class="event-list-chat-btn" [routerLink]="['/messages', {entityId: event.originalEventId}]">
            <img class="img-fluid" style="width: 40px; height: 40px" src="../assets/images/chat_match.png" alt="Chat">
          </a>
        </div>
        <div class="collapse navbar-collapse mx-n3 px-2 shadow-none" id="detailsExtraOptions">
          <ul class="navbar-nav flex-fill mx-n1 mx-sm-0">
            <li class="ml-sm-auto nav-item dropdown d-inline-block" dropdown>
              <a href="javascript:" dropdownToggle class="btn dropdown-toggle no-caret" title="More">
                <i class="fas fa-ellipsis-h"><span class="sr-only">More</span></i>
              </a>

              <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-2 border-top-0" role="menu">
                <a href="javascript:" class="dropdown-item" (click)="viewEvent(event)">Details</a>
                <a href="javascript:" class="dropdown-item" (click)="openStats(event)">Stats</a>
                <a href="javascript:" class="dropdown-item" (click)="openHistory(event)">History</a>
                <div *ngIf="!event.viewedByManager">
                  <div class="dropdown-divider"></div>
                  <a href="javascript:" class="dropdown-item" (click)="leaveEvent(event)">Leave Match</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="header-divider"></div>
  </header>

  <form #eventForm="ngForm" autocomplete="off">

    <div *ngIf="!showHelp" class="form-group">
      <div class="row align-items-center" *ngIf="!loading">

        <div class="col-auto">

          <!-- date component -->
          <div class="m-0 mb-0">
            <label *ngIf="!editMode" class="mb-0">{{event.nextOccurrenceTimestamp | date:'EEEE, d MMMM'}}</label>
          </div>

          <!-- time component -->
          <div class="align-items-center" *ngIf="!loading">
            <div class="m-0 mt-0" id="nextOccurrenceTime">
              <label *ngIf="!editMode" for="nextOccurrenceTime">{{event.nextOccurrenceTimestamp | date:'HH:mm'}}</label>
            </div>
          </div>
        </div>

        <!-- Refresh -->
        <!-- <div *ngIf="event.active && !editMode && event.playerEventInfos.length > 0" class="btn-sm col-auto ml-auto pr-4">
          <a href="javascript:" class="d-inline-block text-success" *ngIf="!fetchPlayerEventInfosInProgress" title="Refresh Data">
            <i class="fas fa-sync-alt h5" (click)="fetchPlayerEventInfos(event); $event.preventDefault()"><span class="sr-only">Refresh Data</span></i>
          </a>
          <div class="spinner-grow" role="status" *ngIf="fetchPlayerEventInfosInProgress">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        Invite
        <div *ngIf="event.viewedByManager && !editMode" class="">
          <button type="button" (click)="dialogSendInviteEmail(event)" class="btn btn-sm btn-warning px-3">Invite</button>
        </div> -->
      </div>

      <div *ngIf="!showHelp" >
        <!-- location -->
        <div class="row align-items-center" *ngIf="!loading">
          <div class="m-0 mb-2 pl-3">
            <a href={{navigateHref}} target="_blank" (click)="openMap();" class="no-underline text-black-50" title="View on Map">
              <i class="fas fa-map-marker-alt fa-">
                <span class="sr-only">View on Map</span></i>&nbsp;{{event.playSpace.name}}, {{event.playSpace.address}}
            </a>
          </div>
        </div>
      </div>

      <!-- MANAGER contact section -->
      <div *ngIf="!editMode && !event.viewedByManager">
        <div class="mt-3 mb-2 pl-3">
          <b class="text-left fr-header">MANAGER</b>
        </div>
      </div>

      <div *ngIf="!editMode && !event.viewedByManager" class="card">
        <div class="card-body text-center py-3">
          <div class="row player-row align-items-center justify-content-center my-n2 ">

            <div class="col-auto my-2">
              <div class="avatar-team">
                <img [src]="event.manager.avatar ? event.manager.avatar  : '../../assets/images/user1.jpg'"
                     onerror="this.src='../../assets/images/user1.jpg'" class="rounded-circle border" style="width: 40px; height: 40px"
                     alt="user profile image">
              </div>
            </div><!-- Avatar -->

            <div class="col-auto pl-0  my-2">
              <label class="mb-0 mw-100 d-inline-block text-truncate">{{event.manager.nickName}}</label>
            </div>

            <div class="col-sm-auto ml-sm-auto d-flex justify-content-center  my-2">
              <a class="btn ml-sm-3 px-4 btn-primary" [href]="'tel: ' + event.manager.phoneNumber">Call</a>
              <button class="btn ml-3 px-4 btn-primary" (click)="enterOrCreateNewChat(event.manager)">Chat</button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of MANAGER contact section -->

      <!-- STATISTICS section -->

      <div *ngIf="!editMode" class="row team-all mt-4 my-0 px-3 align-items-end">
        <div class="col-auto my-0 mb-2">
          <b class="text-left fr-header">MATCH INVITES</b>
        </div>

        <div *ngIf="event.viewedByManager" class="auto ml-auto text-right pr-0">

          <!-- Notify popup -->
          <a class="event-list-button btn-sm cursor-pointer" (click)="showNotifyDialog = !showNotifyDialog">
            <img src="../assets/images/notify_player.png"  style="width: 40px;" alt="Notify players">
          </a>

          <app-dialog [(visible)]="showNotifyDialog">
            <div class="text-center pt-3">
              <img src="../assets/images/notify-player-blue.png"  style="width: 40px;" alt="Notify players">
            </div>
            <div class="text-center pt-3">
              <h6 class="mb-0">Match Invites</h6>
            </div>
            <div class="text-center pt-3">
              <p class="px-5">Invite the following people to the match this {{event.nextOccurrenceTimestamp | date:'EEEE'}}.</p>
            </div>

            <div class="modal-body">
              <div class="col-12"><button type="button" class="btn btn-primary btn-block" (click)="notifyPlayers(event, teamMemberType.FIRST_TEAM); showNotifyDialog = false">1st Team</button></div>
              <div class="col-12 pt-3"><button type="button" class="btn btn-primary btn-block" (click)="notifyPlayers(event, teamMemberType.RESERVE); showNotifyDialog = false">Reserve</button></div>
              <div class="col-12 pt-3"><button type="button" class="btn btn-primary btn-block" (click)="notifyPlayersWithNoResponse(event); showNotifyDialog = false">Remind Invited</button></div>
              <div class="col-12 pt-3"><button type="button" class="btn btn-primary btn-block" (click)="notifyPlayers(event, null); showNotifyDialog = false">All Match Players</button></div>



              <div class="col-12 pt-3"><button type="button" class="btn btn-primary btn-block" (click)="dialogNotifyTeammateOpenProfiles(event); showNotifyDialog = false">SOS your Footballer contacts</button></div>
              <div class="col-12 pt-3"><button type="button" class="btn btn-primary btn-block" (click)="dialogNotifyAllOpenProfiles(event); showNotifyDialog = false">SOS other Footballer players</button></div>

              <!-- <div class="col-12 pt-3"><a class="button btn btn-primary btn-block" target="_blank" href="{{sendFinalEmailHref}}" (click)="sendFinalEmail(event)">Send final email</a></div> -->
            </div>
          </app-dialog>

          <app-dialog [(visible)]="showNotifyAllOpenProfiles">
            <div class="modal-header">
              <h5 class="mb-0">Are you sure you want to send an SOS signal?</h5>
            </div>
            <div class="modal-body">
              <p>This option will invite 50 random players from Footballer to join your match.</p>
              <p>This might be a good option if you do not have enough players.</p>
              <p>You can do this only once per match. </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary text-white btn-sm px-3" (click)="notifyAllOpenProfiles(); showNotifyAllOpenProfiles = false">Yes</button>
              <button type="button" class="btn btn-secondary btn-sm px-3" (click)="showNotifyAllOpenProfiles = false">No</button>
            </div>
          </app-dialog>

          <app-dialog [(visible)]="showNotifyTeammateOpenProfiles">
            <div class="modal-header">
              <h5 class="mb-0"><i class="fas fa-exclamation-triangle text-warning"></i> Are you sure you want to send an SOS signal to wider teammates?</h5>
            </div>
            <div class="modal-body">
              <p>This option will invite all players from other matches where you play (your wider teammates) to join your match.</p>
              <p>This might be a good option if you do not have enough players.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary text-white btn-sm px-3" (click)="notifyTeammateOpenProfiles(); showNotifyTeammateOpenProfiles = false">Yes</button>
              <button type="button" class="btn btn-secondary btn-sm px-3" (click)="showNotifyTeammateOpenProfiles = false">No</button>
            </div>
          </app-dialog>

          <app-dialog [(visible)]="showNotificationsSent">
            <div class="modal-body">
              <h5 class="text-center pt-3">Match invite sent.</h5>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm px-3" (click)="showNotificationsSent = false">Close</button>
            </div>
          </app-dialog>

          <app-dialog [(visible)]="addNewPeopleToNogosh">
            <div class="modal-header">
              <br>
            </div>
            <div class="text-center pt-3">
              <p>An email with instructions has been sent to the list you provided!</p>
              <p>Once they create an account they can request to join your match.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm px-3" (click)="addNewPeopleToNogosh = false">Close</button>
            </div>
          </app-dialog>
          <!-- end of Notify popup(s) -->

          <!-- Invite link and popup -->
          <a *ngIf="event.viewedByManager" class="event-list-button btn-sm cursor-pointer" (click)="showAddPlayersDialog = true">
            <img src="../assets/images/add_player.png"  style="width: 40px;" alt="Add player">
          </a>

          <app-dialog [(visible)]="showInviteDialog" class="p-0 text-left">
            <div class="modal-header">
              <h5 class="m-0">Enter emails to add new people to Footballer!</h5>
            </div>
            <div class="modal-body">
              <p>Use comma separated list of emails <br>(jon.smith@test.com, smith.johnson@another.com,...)</p>
              <p>If a player already has a Footballer account with that email, he'll be added to the match automatically!</p>
              <label class="m-0" for="inviteToNogoshEmailList">Emails to invite:</label>
              <textarea title="emailsToInvite" class="form-control" id="inviteToNogosh" #inviteToNogoshEmailList autofocus></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm px-3 text-white" (click)="showInviteDialog = false">Cancel</button>
              <button type="button" class="btn btn-primary btn-sm px-3" (click)="sendInviteEmail(inviteToNogoshEmailList.value);">Send</button>
            </div>
          </app-dialog>
          <!-- end of Invite link and popup-->
          <!-- add player dialog -->
          <app-dialog [(visible)]="showAddPlayersDialog" class="p-0 text-center">
            <div class="modal-body">
            <div class="mt-auto pt-5 text-center">
              <img class="ml-auto mr-auto pt-5" src="../assets/images/plus-white-background.png">
            </div>
            <div class="mt-auto pt-5 text-center">
            <h5 class="m-0">Add players</h5>
          </div>
            Select players that you want to invite or add more players to this match

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm px-3 text-white" (click)="showAddPlayersDialog = false;showNewNogoshUserDialog = true">Invite new users</button>
              <button type="button" class="btn btn-primary btn-sm px-3" (click)="showAddPlayersDialog = false;showInvitePlayersToMatchDialog = true">Nogosh Players</button>
            </div>
          </app-dialog>
          <!-- add players to match -->
          <app-dialog [(visible)]="showInvitePlayersToMatchDialog" class="p-0 text-left">
            <div class="modal-header">
              <h5 class="m-0">Add new players to match</h5>
            </div>
            <div class="modal-body">
              Select players that you want to add to this match
              <input [(ngModel)]="autocompleteSelectedPlayer" typeaheadOptionsLimit="8"
                placeholder="Add existing Footballer player ( * for all )" autofocus [typeahead]="playerDataSource"
                (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                (typeaheadOnSelect)="typeaheadPlayerNameSelected($event)" typeaheadOptionField="autocompleteName"
                name="autocompletePlayerPicker" class="form-control" id="eventPlayers" #newPlayer autocomplete="off">
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
              <div *ngIf="typeaheadPlayerLoading === true"><i class="fas fa-sync ng-hide"></i></div>
              <div *ngIf="typeaheadPlayerNoResults === true"><i class="fas fa-trash-alt col-sm-offset-1"></i> No results found</div>

              <div class="mb-4 mb-md-5">
                <div *ngFor="let player of selectedPlayers" id="playersEditMode">
                  <div class="border-bottom border-bottom-not-last">
                    <div *ngIf="event.viewedByManager" class="row text-sm pt-2">

                      <!-- nickname -->
                      <div class="col-auto" title="{{player.nickName}}">
                        <label for="playersEditMode" cl>{{player.nickName}}</label>
                      </div>
                      <!-- remove -->
                      <div class="col-auto ml-auto">
                        <a href="javascript:void(0)" class="h5 text-danger d-inline-block"
                          (click)="removePlayerFromInviteList(player)" [ngClass]="player.id === 1 ? 'disabled' : ''"><i
                            class="fas fa-times-circle"><span class="sr-only">Remove Player</span></i></a>
                      </div>
                    </div>
                  </div>
                </div><!-- #playersEditMode -->
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm px-3 text-white"
                (click)="showInvitePlayersToMatchDialog =  false">Cancel</button>
              <button type="button" class="btn btn-primary btn-sm px-3" (click)="showInvitePlayersToMatchDialog = false; callPlayersFromInviteList()">Add</button>
            </div>
          </app-dialog>
          <!-- call new user to Nogosh -->
          <app-dialog [(visible)]="showNewNogoshUserDialog" class="p-0 text-center">
            <div class="modal-header">
              <h5 class="m-0">Call new users to join Footballer</h5>
            </div>
            <div class="modal-body">
              <div class="mt-auto pt-5 text-center">
                <h5 class="m-0">Copy & Invite!</h5>
                <br/>
              </div>
              <p #inviteMessage>Yo! I am inviting you to Footballer <a href="https://app.footballer.zone">https://app.footballer.zone/</a> so we can play some football together.
              Log into Footballer with your Gmail, Facebook or create an account with an email and join the match {{event.name}}! Long live football!</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-sm px-3 text-white"
              (click)="copyNogoshMessage(inviteMessage)">Copy</button>
              <button type="button" class="btn btn-secondary btn-sm px-3 text-white"
                (click)="showNewNogoshUserDialog = false">Close</button>
            </div>
          </app-dialog>
        </div>
      </div>

      <div *ngIf="!editMode" class="card">
        <div class="card-body text-center py-3">
          <span class="pr-3 border-right mr-3 align-middle" title="{{countOfConfirmed}} Confirmed"><i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"></i>  {{countOfConfirmed}}/{{event.maxPlayers}} <span class="sr-only">Confirmed</span></span>
          <span class="pr-3 border-right mr-3 align-middle" title="{{countOfNotAnswered}} Not Answered"><i class="fa fa-question-circle mr-1 text-warning" aria-hidden="true"></i> {{countOfNotAnswered}} <span class="sr-only">Not Answered</span></span>
          <span class="pr-3 border-right mr-3 align-middle" title="{{countOfDeclined}} Declined"><i class="fa fa-times-circle mr-1 text-danger" aria-hidden="true"></i> {{countOfDeclined}} <span class="sr-only">Declined</span></span>
          <span class="align-middle" title="{{countOfNotNotified}} Not Notified"><i class="fa fa-minus-circle mr-1 text-secondary" aria-hidden="true" style="color: #ff0000"></i> {{countOfNotNotified}} <span class="sr-only">Not Notified</span></span>
        </div>
      </div>
      <!-- end of STATISTICS section -->

      <!-- YOUR ANSWER section -->

      <div *ngIf="!editMode">
        <div class="mt-3 mb-2 pl-3">
          <b class="text-left fr-header">YOUR ANSWER</b>
        </div>
      </div>

      <div *ngIf="!editMode" class="card">
        <div class="card-body text-center py-3">
          <div class="row player-row align-items-center">

            <div class="col-auto">
              <div class="avatar-team">
                <img [src]="currentPlayer.avatar ? currentPlayer.avatar  : '../../assets/images/user1.jpg'"
                     onerror="this.src='../../assets/images/user1.jpg'" class="rounded-circle border" style="width: 40px; height: 40px"
                     alt="user profile image">
              </div>
            </div><!-- Avatar -->

            <div class="col-auto pl-0 align-items-center d-none d-sm-inline-block">
              <label class="mb-0 mw-100">{{currentPlayer.nickName}}</label>
            </div>

            <div class="col-auto ml-auto pl-0 d-flex">
              <!-- play -->

                <button *ngIf="event.currentPlayerInviteStatus === playerEventInfoStatus.CONFIRMED" [disabled]="event.active === false"
                        class="btn  ml-sm-3 px-4 btn-outline-primary active">Play</button>
                <button *ngIf="event.currentPlayerInviteStatus !== playerEventInfoStatus.CONFIRMED && (event.currentPlayerInviteStatus !== playerEventInfoStatus.NOT_NOTIFIED || (event.currentPlayerInviteStatus === playerEventInfoStatus.NOT_NOTIFIED && event.viewedByManager))" [disabled]="event.active === false"  (click)="respondToInvite(event, playerEventInfoStatus.CONFIRMED)"
                        class="btn  ml-sm-3 px-4 btn-outline-primary">Play</button>


              <!-- pass -->

                <button [disabled]="event.active === false"  *ngIf="event.currentPlayerInviteStatus === playerEventInfoStatus.DECLINED"
                        class="btn  ml-3 px-4 btn-outline-primary active">Pass</button>
                <button *ngIf="event.currentPlayerInviteStatus !== playerEventInfoStatus.DECLINED && (event.currentPlayerInviteStatus !== playerEventInfoStatus.NOT_NOTIFIED || (event.currentPlayerInviteStatus === playerEventInfoStatus.NOT_NOTIFIED && event.viewedByManager))" [disabled]="event.active === false" (click)="respondToInvite(event, playerEventInfoStatus.DECLINED)"
                        class="btn  ml-3 px-4 btn-outline-primary">Pass</button>

                <button [disabled]="true"  *ngIf="event.currentPlayerInviteStatus === playerEventInfoStatus.NOT_NOTIFIED && !event.viewedByManager"  class="btn  ml-3 px-4 btn-outline-primary">Not Invited</button>

            </div>
          </div>
        </div>
      </div>
      <!-- end of YOUR ANSWER section -->

      <!-- INVITE FRIENDS section -->

      <div *ngIf="!editMode">
        <div class="mt-3 mb-2 pl-3">
          <b class="text-left fr-header">INVITE FRIENDS</b>
        </div>
      </div>

      <div *ngIf="!editMode" class="card" (click)="openShareMenu()">
        <div class="card-body text-center py-3">
          <div class="row player-row align-items-center">

            <div class="col-auto">
              <div class="avatar-team">
                <img src="../../assets/images/inviteplayer_icon@3x.png"
                      class="rounded-circle border" style="width: 40px; height: 40px"
                      alt="user profile image">
              </div>
            </div><!-- Avatar -->

            <div class="col pl-0 text-sm font-weight-500 players-nickname d-flex flex-column line-height-10">
              Invite new players
              <span class="text-nogosh-grey3 text-sm mt-1" placement="top">
                Share the link to your friends!
              </span>  
            </div>
          </div>
        </div>
      </div>
      <!-- end of INVITE FRIENDS section -->

      <!-- player autocomplete -->
      <!-- <div *ngIf="editMode" class="col-xs-12 mt-2">
        <input [(ngModel)]="autocompleteSelectedPlayer" typeaheadOptionsLimit="10" placeholder="Add existing Nogosh player ( * for all )" autofocus
               [typeahead]="playerDataSource" (typeaheadLoading)="changeTypeaheadLoading($event)"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               (typeaheadOnSelect)="typeaheadPlayerNameSelected($event); addPlayerToTheEvent(autocompleteSelectedPlayer.email)"
               typeaheadOptionField="autocompleteName" name="autocompletePlayerPicker"
               class="form-control" id="eventPlayers" #newPlayer autocomplete="off" >

        <div *ngIf="typeaheadPlayerLoading === true"><i class="fas fa-sync ng-hide"></i></div>
        <div *ngIf="typeaheadPlayerNoResults === true"><i class="fas fa-trash-alt col-sm-offset-1"></i> No results found</div>
      </div> -->

      <!-- CANDIDATES section -->

      <div *ngIf="!editMode && newCandidatesInfoList.length>0">
        <div class="mt-3 mb-2 pl-3">
          <b class="text-left fr-header">CANDIDATES</b>
        </div>
      </div>


      <div *ngIf="!editMode && newCandidatesInfoList.length>0 ">
          <div class="players-list-wrapper card">
            <div class="card-body px-3 py-0">
            <div *ngFor="let playerEventInfo of newCandidatesInfoList" id="candidatesList" class="players-list py-2 border-bottom border-bottom-not-last">
              <div *ngIf="playerEventInfo.teamMemberType === teamMemberType.CANDIDATE">
                <div class="row player-row align-items-center">
                  <div class="col-auto pr-2 pr-sm-3">
                  <div class="avatar-team">
                    <img [src]="playerEventInfo.player.avatar ? playerEventInfo.player.avatar  : '../../assets/images/user1.jpg'"
                          onerror="this.src='../../assets/images/user1.jpg'" class="rounded-circle border" style="width: 40px; height: 40px"
                          alt="user profile image">
                  </div>
                </div>
                  <div class="col pl-0 text-sm font-weight-500 players-nickname d-flex flex-column justify-content-center line-height-10"
                    title="{{playerEventInfo.player.nickName}}">
                    {{playerEventInfo.player.nickName}}
                  </div>
                  <div *ngIf="this.event.viewedByManager" class="col-sm-auto ml-3 px-3 ml-sm-auto d-flex justify-content-center">                    
                    <button (click)="openMngPlayerDetail(playerEventInfo, event); $event.preventDefault()" class="btn  mr-sm-3 px-4 btn-outline-primary">Info</button>
                    <button (click)="approveJoinRequest(playerEventInfo); $event.preventDefault()" class="btn  ml-sm-3 px-4 btn-outline-primary">Accept</button>
                    <button (click)="removePlayerFromTheEvent(playerEventInfo); $event.preventDefault()" class="btn  ml-3 px-4 btn-outline-primary">Deny</button>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- end of CANDIDATES section -->

      <div *ngIf="!editMode && event.historyEvent">
        <div class="fr-header mt-3 mb-2 pl-3"><b>RESULT</b></div>
        <div *ngIf="!editMode" class="card">
          <div class="card-body text-center py-3">
            <i class="fas fa-tshirt h2 m-0 text-secondary"></i>
            <span class="fr-header m-5">{{event.whiteScore}} : {{event.blackScore}}</span>
            <i class="fas fa-tshirt h2 m-0 text-primary"></i>
            <br/>
            
            <div [ngSwitch]="event.wonLostDrewNothing">
              <i *ngSwitchCase="'won'" class="fas fa-trophy text-warning" title="You won this one!! :)"></i>
              <i *ngSwitchCase="'lost'" class="fas fa-poop text-primary" title="You lost this one!! :("></i>
              <i *ngSwitchCase="'drew'" class="far fa-grin-tongue-wink text-secondary" title="You drew this one!! :|"></i>
              <i *ngSwitchCase="'nothing'" class="fas fa-minus text-secondary" title="You did not play this one"></i>
              <i *ngSwitchDefault></i>
            </div>
          </div>
        </div>
      </div>


      <div class="rows" *ngIf="!loadingPlayersList">
        <div *ngFor="let player of event.playerEventInfos | groupBy : 'status' | sortGroupedBy : 'key'" id="eventPlayersList">
          <div class="fr-header mt-3 mb-2 pl-3" *ngIf="player.key === 'CONFIRMED' ">
            <b>COMING</b>
            <button *ngIf="event.viewedByManager" [disabled]="event.active === false"
            class="btn  ml-sm-3 px-4 btn-outline-primary active" (click)="assignTeams()">Balance Teams</button>
          </div>

          <div class="fr-header mt-3 mb-2 pl-3 d-flex align-items-end " *ngIf="player.key === 'NOT_ANSWERED' ">
            <b>NOT ANSWERED</b>

            <div *ngIf="false" class="text-right d-inline-block ml-auto mb-n2 pr-2">
              <a class="event-list-button btn-sm" [routerLink]="['/messages', {entityId: event.originalEventId}]">
                <img src="../assets/images/notify_player.png"  style="width: 40px;" alt="Notify players">
              </a>
            </div>
          </div>

          <div class="fr-header mt-3 mb-2 pl-3" *ngIf="player.key === 'DECLINED' "><b>NOT COMING</b></div>
          <div class="fr-header mt-3 mb-2 pl-3" *ngIf="player.key === 'NOT_NOTIFIED' "><b>NOT INVITED</b></div>

          <div class="players-list-wrapper card">
            <div *ngIf="player.key === 'CONFIRMED'" class="card-body px-3 py-0">
              <div *ngFor="let playerEventInfo of player.value | sortBy : 'jersey' | sortBy : 'player.nickName'" class="players-list py-2 border-bottom border-bottom-not-last">

                <div class="row player-row align-items-center">

                  <!-- avatar -->
                  <div class="col-auto pr-2 pr-sm-3">
                    <div class="avatar-team">
                      <img [src]="playerEventInfo.player.avatar ? playerEventInfo.player.avatar  : '../../assets/images/user1.jpg'"
                           onerror="this.src='../../assets/images/user1.jpg'" class="rounded-circle border" style="width: 40px; height: 40px"
                           alt="user profile image">
                    </div>
                  </div>

                  <!-- nickname -->
                  <div class="col pl-0 text-sm font-weight-500 players-nickname d-flex flex-column justify-content-center line-height-10"
                    title="{{playerEventInfo.player.nickName}}">
                    {{playerEventInfo.player.nickName}}
                    <span *ngIf="playerEventInfo.teamMemberType === teamMemberType.RESERVE"
                      class="text-nogosh-grey3 text-sm mt-1" popoverTitle="Player type" placement="top">
                      Reserve
                    </span>
                    <span *ngIf="playerEventInfo.teamMemberType === teamMemberType.FIRST_TEAM"
                      class="text-nogosh-grey3 text-sm mt-1" popoverTitle="Player type" placement="top">
                      1st Team
                    </span>
                  </div>

                  <!-- jersey -->
                  <div class="ml-auto mr-4 d-flex align-items-center"
                       *ngIf="event.viewedByManager && !editMode && playerEventInfo.status === playerEventInfoStatus.CONFIRMED" title="Toggle Player's Jersey">
                    <a href="javascript:" *ngIf="playerEventInfo.jersey === playerEventInfoJersey.WHITE" class="text-secondary" title="Change Jersey Light">
                      <i class="fas fa-tshirt h2 m-0" (click)="toggleSubteamJersey(playerEventInfo); $event.preventDefault()"></i>
                    </a>
                    <a href="javascript:" *ngIf="playerEventInfo.jersey === playerEventInfoJersey.BLACK" class="text-primary" title="Change Jersey Dark">
                      <i class="fas fa-tshirt h2 m-0" (click)="toggleSubteamJersey(playerEventInfo); $event.preventDefault()"></i>
                    </a>
                  </div>
                  <div *ngIf="!event.viewedByManager" class="ml-auto mr-4 d-flex align-items-center">
                    <div *ngIf="playerEventInfo.status === playerEventInfoStatus.CONFIRMED">
                      <span *ngIf="playerEventInfo.jersey === playerEventInfoJersey.WHITE" class="text-secondary" title="Jersey Light">
                          <i class="fas fa-tshirt h2 m-0" (click)="$event.preventDefault()"></i>
                      </span>
                      <span *ngIf="playerEventInfo.jersey === playerEventInfoJersey.BLACK" class="text-primary" title="Jersey Dark">
                          <i class="fas fa-tshirt h2 m-0" (click)="$event.preventDefault()"></i>
                      </span>
                    </div>
                  </div>

                  <!-- arrow -->
                  <div  class="col-auto align-items-center cursor-pointer" (click)="openMngPlayerDetail(playerEventInfo, event)">
                    <i class="fas fa-angle-right h3 m-0" ></i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="player.key !=='CONFIRMED'" class="card-body px-3 py-0">
              <ng-container *ngFor="let playerEventInfo of player.value">
                <ng-container *ngIf="playerEventInfo.teamMemberType !==teamMemberType.CANDIDATE">
                  <!-- *ngIf="playerEventInfo.teamMemberType !==teamMemberType.CANDIDATE" -->
  
                  <div class="players-list py-2 border-bottom border-bottom-not-last">
                

                    <div class="row player-row align-items-center">

                      <!-- avatar -->
                      <div class="col-auto pr-2 pr-sm-3">
                        <div class="avatar-team">
                          <img [src]="playerEventInfo.player.avatar ? playerEventInfo.player.avatar  : '../../assets/images/user1.jpg'"
                              onerror="this.src='../../assets/images/user1.jpg'" class="rounded-circle border" style="width: 40px; height: 40px"
                              alt="user profile image">
                        </div>
                      </div>

                      <!-- nickname -->
                      <div class="col pl-0 text-sm font-weight-500 players-nickname d-flex flex-column justify-content-center line-height-10"
                        title="{{playerEventInfo.player.nickName}}">
                        {{playerEventInfo.player.nickName}}
                        <span *ngIf="playerEventInfo.teamMemberType === teamMemberType.RESERVE"
                          class="text-nogosh-grey3 text-sm mt-1" popoverTitle="Player type" placement="top">
                          Reserve
                        </span>
                        <span *ngIf="playerEventInfo.teamMemberType === teamMemberType.FIRST_TEAM"
                          class="text-nogosh-grey3 text-sm mt-1" popoverTitle="Player type" placement="top">
                          1st Team
                        </span>
                      </div>

                      <!-- jersey -->
                      <div class="ml-auto mr-4 d-flex align-items-center"
                          *ngIf="event.viewedByManager && !editMode && playerEventInfo.status === playerEventInfoStatus.CONFIRMED" title="Toggle Player's Jersey">
                        <a href="javascript:" *ngIf="playerEventInfo.jersey === playerEventInfoJersey.WHITE" class="text-secondary" title="Change Jersey Light">
                          <i class="fas fa-tshirt h2 m-0" (click)="toggleSubteamJersey(playerEventInfo); $event.preventDefault()"></i>
                        </a>
                        <a href="javascript:" *ngIf="playerEventInfo.jersey === playerEventInfoJersey.BLACK" class="text-primary" title="Change Jersey Dark">
                          <i class="fas fa-tshirt h2 m-0" (click)="toggleSubteamJersey(playerEventInfo); $event.preventDefault()"></i>
                        </a>
                      </div>
                      <div *ngIf="!event.viewedByManager" class="ml-auto mr-4 d-flex align-items-center">
                        <div *ngIf="playerEventInfo.status === playerEventInfoStatus.CONFIRMED">
                          <span *ngIf="playerEventInfo.jersey === playerEventInfoJersey.WHITE" class="text-secondary" title="Jersey Light">
                              <i class="fas fa-tshirt h2 m-0" (click)="$event.preventDefault()"></i>
                          </span>
                          <span *ngIf="playerEventInfo.jersey === playerEventInfoJersey.BLACK" class="text-primary" title="Jersey Dark">
                              <i class="fas fa-tshirt h2 m-0" (click)="$event.preventDefault()"></i>
                          </span>
                        </div>
                      </div>

                      <!-- arrow -->
                      <div  class="col-auto align-items-center cursor-pointer" (click)="openMngPlayerDetail(playerEventInfo, event)">
                        <i class="fas fa-angle-right h3 m-0" ></i>
                      </div>
                    </div>
                  </div> 
                </ng-container>
              </ng-container>
            </div>
      </div>
    </div>
      </div>
      <div  *ngIf="event.viewedByManager" class="mt-auto pt-5 text-center ng-star-inserted">
        <div class="col-12">
          <button (click)="openAddPlayersDialog()" class="btn btn-primary px-3">ADD PLAYERS</button>
        </div>
    </div>
    </div>
  </form>
</div>
