import { CommonModule } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthModule } from "@auth0/auth0-angular";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ChatModule } from "./chat/chat.module";
import { CoreModule } from "./core/core.module";
import { DialogComponent } from "./core/dialog/dialog.component";
import { environment } from "./environments/environment";
import { EventModule } from "./event/event.module";
import { FeedbackModule } from "./feedback/feedback.module";
import { FooterComponent } from "./footer/footer.component";
import { HelpModule } from "./help/help.module";
import { AdminModule } from "./admin/admin.module";
import { PlayerModule } from "./player/player.module";
import { PlaySpaceModule } from "./playspace/play-space.module";
import { LoadingProgressComponent } from "./shared/loading-progress/loading-progress.component";
import { NavBarComponent } from "./shared/nav-bar/nav-bar.component";
import { KeysPipe } from "./shared/pipes/keys.pipe";
import Auth0InterceptorApi from "./shared/services/auth0.interceptor";
import { LogService } from "./shared/services/log.service";
import { CustomErrorHandler } from "./shared/utils/custom.error.handler";
import { StatsModule } from "./stats/stats.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { FooterModule } from "./footer/footer.module";
import { GoogleMapsModule } from '@angular/google-maps';
import { RatingModule } from 'ngx-bootstrap/rating';

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => localStorage.getItem("id_token"),
    whitelistedDomains: environment.whiteListDomains,
    globalHeaders: [{ "Content-Type": "application/json" }],
  };
}

@NgModule({
  declarations: [AppComponent, KeysPipe, LoadingProgressComponent, NavBarComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    LogService,
    { provide: HTTP_INTERCEPTORS, useClass: Auth0InterceptorApi, multi: true },
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CommonModule,
    CoreModule,
    ChatModule,
    //AgmCoreModule.forRoot({
      //apiKey: environment.apiKey,
    //}),
    GoogleMapsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TabsModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    BrowserAnimationsModule,
    InfiniteScrollModule,
    PlayerModule,
    PlaySpaceModule,
    EventModule,
    StatsModule,
    FeedbackModule,
    HelpModule,
    AdminModule,
    FooterModule,
    ModalModule.forRoot(),
    AuthModule.forRoot({
      domain: "mangonel.eu.auth0.com",
      clientId: "tB8ObxF3n30ad48ba0LxfCMeWT4OwwYE",
      audience: "https://mangonel.eu.auth0.com/userinfo",
      httpInterceptor: {
        allowedList: [{ uri: "https://app.staging.footballer.zone/api/v0/**" }],
      },
    }),
    RatingModule
  ],

  exports: [KeysPipe, DialogComponent, BsDropdownModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
