<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
><p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="!loading" class="container" role="main">

  <header class="row my-3 my-md-4 mb-md-5 align-items-center">
    <a *ngIf="!editMode" href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
    <a *ngIf="editMode" href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="cancelEventDetailsChange()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
    <div class="col">
      <h2 class="m-0">
        <span *ngIf="!event.id" class="animated">Create </span>
        <span *ngIf="event.id && editMode" class="animated">Edit </span>
        {{event.name}} <span *ngIf="!editMode" class="animated">details</span>
      </h2>
    </div>

    <div class="col-auto ml-auto" *ngIf="event.active && !event.viewedByCandidate && !event.viewedByPlayer && !event.viewedByManager">
      <button type="button" (click)="requestToJoin(event);" class="btn btn-xs btn-warning">Join</button>
    </div>
    <div class="col-auto ml-auto" *ngIf="event.active && event.viewedByCandidate">
      <div *ngIf="event.viewedByCandidate" class="">
        <button type="button" class="btn btn-xs btn-warning" disabled>Join pending</button>
      </div>
    </div>
    
    <!-- Manage players and Modify -->
    <div *ngIf="!editMode && event.viewedByManager" class="col-auto ml-auto d-flex mt-auto mb-1 mb-md-auto">
      <div class="ml-3 ml-md-2">
        <a href="javascript:" (click)="editEvent()" class="btn btn-sm btn-warning px-3">
          <i class="fas fa-pen d-md-none mx-1"></i>
          <span class="d-none d-md-inline-block">Modify</span>
        </a>
      </div>
    </div>

    <div class="header-divider"></div>
  </header>


  <form (ngSubmit)="onSubmit(event.viewedByManager)" autocomplete="off" #eventForm="ngForm" class="col-lg-10 mx-auto">

    <div class="heading">
      <!-- Heading -->
      <div class="d-empty-none mt-lh-n1">
        <div *ngIf="!editMode" class="mt-md-n4 mb-4">
          <a href="{{ navigateHref }}" target="_blank" (click)="openMap()" class="mt-md-n4 mb-4" title="View on Map">
            <i class="fas fa-map-marker-alt fa-"><span class="sr-only">View on Map</span></i>&nbsp;{{ event.playSpace.name }}
            <label class="d-inline-block">{{ event.nextOccurrenceTimestamp | date: "MMM d yyyy @ HH:mm" }}h</label>
          </a>
        </div>
      </div>
    </div>



    <!-- second row - Form elements -->
    <div class="form-group row">

      <!-- //////////////////////////////////////////////////////////////// -->
      <!-- left side -->
      <div class="col-md-6 col-12">

        <div *ngIf="!event.active">
          <div class="form-group">
            <label for="eventNote4" class="mb-0">Notes and match aftermath</label>
            <textarea class="form-control" [rows]="event.note?.length > 30 ? 5 : 1" id="eventNote4" name="eventNote"
                      [(ngModel)]="event.note" [disabled]="!editMode || !event.viewedByManager"></textarea>
          </div>

          <label for="whiteScore"  class="border-bottom d-block">Score</label>
          <div class="d-flex">
            <div class="form-group d-inline-flex flex-column">
              <label for="whiteScore"  class="mb-0">Whites</label>
              <input class="form-control" id="whiteScore" name="whiteScore" [(ngModel)]="event.whiteScore"
                     [disabled]="!(editMode && event.viewedByManager)"/>
            </div>
            <div class="d-inline-flex flex-column px-1"><label>&nbsp;</label><span>:</span></div>
            <div class="form-group d-inline-flex flex-column">
              <label for="blackScore"  class="mb-0">Blacks:</label>
              <input class="form-control" id="blackScore" name="blackScore" [(ngModel)]="event.blackScore"
                     [disabled]="!(editMode && event.viewedByManager)"/>
            </div>


          </div>
        </div>

        <div *ngIf="!editMode || (editMode && event.active)" class="form-group">
          <label for="eventName" class="m-0">Match Name</label>
          <input type="text" class="form-control" id="eventName" placeholder="Enter short name" [(ngModel)]="event.name"
                 name="name" [disabled]="!event.active || !editMode || !event.viewedByManager" required>
        </div>

        <div *ngIf="!editMode || (editMode && event.active)" class="form-group">
          <label for="maxPlayers" class="m-0">Max players per game</label>
          <input type="text" class="form-control" id="maxPlayers" placeholder="Total number of players on this match" [(ngModel)]="event.maxPlayers"
                 name="maxPlayers" [disabled]="!event.active || !editMode || !event.viewedByManager" required>
        </div>

        <div *ngIf="!editMode" class="form-group">
          <label for="eventPlaySpaceName" class="m-0">Match location</label>
          <input type="text" class="form-control" id="eventPlaySpaceName"
                 [(ngModel)]="event.playSpace.name" name="playSpaceName" disabled required>
        </div>

        <div *ngIf="!editMode" class="form-group">
          <label for="eventManager" class="m-0">Match coordinator</label>
          <input type="text" class="form-control" id="eventManager" placeholder="Change match coordinator"
                 [(ngModel)]="event.manager.name" name="manager" [disabled]="!editMode">
        </div>

        <!-- manager autocomplete -->
        <div *ngIf="editMode && event.active" class="form-group">
          <label for="eventManager" class="m-0">Match coordinator</label>

          <input [(ngModel)]="autocompleteSelectedPlayer" class="form-control" placeholder="Select manager of this match ( * for all )"
                 (typeaheadOnSelect)="typeaheadManagerEmailSelected($event)"
                 [typeahead]="playerDataSource" (typeaheadLoading)="changeTypeaheadLoading($event, 'managerName')"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event, 'managerName')"
                 typeaheadOptionField="autocompleteName" typeaheadOptionsLimit="10"
                 id="eventManagerAutocomplete" #newPlayer autocomplete="off" name="autocompletePlayerPicker" required>
          <div *ngIf="typeaheadPlayerLoading === true">
            <i class="fas fa-sync ng-hide"></i>
          </div>
          <div *ngIf="typeaheadPlayerNoResults === true">
            <i class="fas fa-trash-alt col-sm-offset-1"></i> No results found
          </div>
        </div>


        <div>
          <!-- Match location autocomplete - editMode -->
          <div class="form-group" *ngIf="editMode && event.viewedByManager">
            <label for="eventPlaySpaceName" class="m-0">Match location</label>

            <input [(ngModel)]="autocompletePlayspace" (blur)="checkIfLocationNameIsSelected()" autocomplete="off" required
                   [value]="event.playSpace.name" name="autocompleteLocationNamePicker" [typeahead]="locationDataSource"
                   (typeaheadOnSelect)="typeaheadLocationNameSelected($event)"
                   (typeaheadLoading)="changeTypeaheadLoading($event, 'locationName')"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event, 'locationName')" typeaheadOptionField="name"
                   typeaheadOptionsLimit="10" placeholder="Select from existing match locations" class="form-control">
            <div *ngIf="typeaheadLocationLoading === true">
              <i class="fas fa-sync ng-hide"></i>
            </div>
            <div *ngIf="typeaheadLocationNoResults === true">
              <i class="fas fa-trash-alt col-sm-offset-1"></i> No results found
            </div>
          </div>
          <p>
            <a *ngIf="editMode && event.viewedByManager" (click)="saveEventState()" [routerLink]="['/playspace', true]">
              <i  class="fas fa-map-marker-alt h5 m-0 mr-1 align-middle text-secondary"></i> <span class="font-weight-500">or Add your own new location</span> <i class="ml-2 fas fa-angle-right align-middle"></i>
            </a>
          </p>
          <google-map *ngIf="editMode && event.viewedByManager" [options]="mapOptions" height="350px" width="100%">
            <map-marker *ngFor="let m of markers; let i = index" #marker="mapMarker" (mapClick)="openInfoWindow(marker, i)"  [position]="m.googleLatLng">
              <map-info-window>
                <ul class="list-unstyled text-md mb-0">
                  <li class="mb-1"><strong class="font-weight-600">{{m.name}}</strong></li>
                  <li><span>{{m.address}}, {{m.city.name}}, {{m.city.country.name}}</span></li>

                  <li  class="my-1 mt-2"><span>Working hours: </span><strong>{{m.workingHours}}</strong></li>
                  <li class="my-1"><span>Phone: </span><strong>{{m.phone}}</strong></li>
                  <li class="my-1 mb-2"><span>Web: </span><a target="_blank" href="{{m.webAddress}}"><strong>{{m.webAddress}}</strong></a><li>

                  <li class="mt-2"><strong class="font-weight-500"><a href="" (click)="choosePlayLocation(m); $event.preventDefault()">Select this location<i  class="ml-2 fas fa-angle-right align-baseline "></i></a></strong></li>
                </ul>
              </map-info-window>
            </map-marker>
          </google-map>
          <!-- <agm-map *ngIf="editMode && event.viewedByManager"
                   [latitude]="mapLatitude"
                   [longitude]="mapLongitude"
                   [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
                   [style.height.px]="350">
            <agm-marker *ngFor="let m of markers" [latitude]="m.latitude" [longitude]="m.longitude" [markerDraggable]="false">
              <agm-info-window>
                <ul class="list-unstyled text-md mb-0">
                  <li class="mb-1"><strong class="font-weight-600">{{m.name}}</strong></li>
                  <li><span>{{m.address}}, {{m.city.name}}, {{m.city.country.name}}</span></li>

                  <li  class="my-1 mt-2"><span>Working hours: </span><strong>{{m.workingHours}}</strong></li>
                  <li class="my-1"><span>Phone: </span><strong>{{m.phone}}</strong></li>
                  <li class="my-1 mb-2"><span>Web: </span><a target="_blank" href="https://{{m.webAddress}}"><strong>{{m.webAddress}}</strong></a><li>

                  <li class="mt-2"><strong class="font-weight-500"><a href="" (click)="choosePlayLocation(m); $event.preventDefault()">Select this location<i  class="ml-2 fas fa-angle-right align-baseline "></i></a></strong></li>
                </ul>
              </agm-info-window>
            </agm-marker>
          </agm-map> -->

        </div><!-- /.location -->



      </div>

      <!-- //////////////////////////////////////////////////////////////// -->
      <!-- right side -->

      <div class="col-md-6">

        <!-- date picker - editMode -->
        <div class="form-group text-center text-md-left mt-3 mt-md-0" *ngIf="editMode && event.viewedByManager && event.active">
          <label for="eventScheduledDateEdit">Next occurrence date and time</label>
          <div class="row">
            <div class="pr-3 pb-3 pe-3 w-auto">
              <bs-datepicker-inline [(bsValue)]="selectedDate" name="eventScheduledDate" id="eventScheduledDateEdit" [minDate]="currentDate" [showWeeks]="false" class="d-picker d-inline-block ml-auto" [bsConfig]="bsConfig"></bs-datepicker-inline>
            </div>
          </div>


          <div class="">
            <div class="">
              <!-- <input type="text" placeholder="Datepicker" bsDatepicker [(ngModel)]="selectedDate" name="eventScheduledDate" id="eventScheduledDateEdit" [minDate]="currentDate" [showWeeks]="false" class="form-control d-picker d-inline-block ml-auto"> -->
            </div>
          </div>
        </div>

        <!-- date and time picker - nextOccurrence - editMode -->
        <div class="form-group" *ngIf="editMode && event.viewedByManager && event.active">
          <div class="row flex-column flex-sm-row flex-lg-nowrap">
            <div class="col-auto d-flex align-items-start order-lg-2">
              <strong class="h5 mx-0 my-3">
                {{selectedHour != 0 ? ((selectedHour < 10 ? '0' : '' ) + selectedHour) : '00' }}:{{selectedMinute !=0 ? ((selectedMinute < 10 ? '0' : '' ) + selectedMinute) : '00' }}h
              </strong>
            </div><!-- /.col  -->
            <div class="col flex-column order-1 order-sm-0">
              <table class="table table-borderless d-block d-sm-table m-0 mt-1">

                <tbody class="d-block d-sm-table">
                <tr class="d-block d-sm-table-row">
                  <td class="p-0 pr-2 align-middle d-block d-sm-table-cell"><label class="mb-0 mb-sm-2" for="hoursRange">Hour: </label></td>
                  <td class="w-100 p-0 align-middle d-block d-sm-table-cell">
                    <input class="custom-range" type="range" id="hoursRange" [(ngModel)]="selectedHour" name="selectedHour" min="0" max="23" step="1">
                  </td>
                </tr>
                <tr class="d-block d-sm-table-row">
                  <td class="p-0 pr-2 align-middle d-block d-sm-table-cell"><label class="mb-0 mb-sm-2" for="minutesRange">Minute: </label></td>
                  <td class="w-100 p-0 align-middle d-block d-sm-table-cell">
                    <input class="custom-range" type="range" id="minutesRange" #minutesSlider [(ngModel)]="selectedMinute" name="selectedMinute"
                           min="0" max="59" step="1">
                  </td>
                </tr>
                <tr class="d-block d-sm-table-row" *ngIf="editMode && event.viewedByManager && event.active">
                  <td class="p-0 pr-2 align-middle d-block d-sm-table-cell"><label for="radioRecurringEdit0" class="mb-0 mr-3">Recurring: </label></td>
                  <td class="w-100 p-0 align-middle d-block d-sm-table-cell text-nowrap">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="radioRecurringEdit" name="customRadioInline1" class="custom-control-input" [value]="radioRecurringType.ONE_OFF" [(ngModel)]="event.recurringType" name="radioRecurring">
                      <label class="custom-control-label d-flex align-items-center mr-2 cursor-pointer" for="radioRecurringEdit">One-off</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="radioRecurringEdit2" name="customRadioInline1" class="custom-control-input" [value]="radioRecurringType.DAILY" [(ngModel)]="event.recurringType" name="radioRecurring">
                      <label class="custom-control-label d-flex align-items-center mr-2  cursor-pointer" for="radioRecurringEdit2">Daily</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="radioRecurringEdit3" name="customRadioInline1" class="custom-control-input" [value]="radioRecurringType.WEEKLY" [(ngModel)]="event.recurringType" name="radioRecurring">
                      <label class="custom-control-label d-flex align-items-center cursor-pointer" for="radioRecurringEdit3">Weekly</label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div><!-- /.col -->


          </div><!--  /.row -->


        </div>

        <!-- recurring - editMode -->
        <!-- <div class="form-group" *ngIf="editMode && event.viewedByManager && event.active">
         <div>
           <input class="btn btn-primary" type="radio" [(ngModel)]="event.recurringType" name="radioRecurring"
                 id="radioRecurringEdit" [value]="radioRecurringType.ONE_OFF"> One-off
           <input class="btn btn-primary" type="radio" [(ngModel)]="event.recurringType" name="radioRecurring"
                 id="radioRecurringEdit2" [value]="radioRecurringType.DAILY"> Daily
           <input class="btn btn-primary" type="radio" [(ngModel)]="event.recurringType" name="radioRecurring"
                 id="radioRecurringEdit3" [value]="radioRecurringType.WEEKLY"> Weekly
         </div>
       </div>-->

        <!-- details - viewMode -->
        <div *ngIf="!editMode">
          <div class="form-group">
            <label for="recurringViewMode" class="mb-0">Recurring</label>
            <input type="text" class="form-control" id="recurringViewMode" [(ngModel)]="event.recurringType" name="viewRecurring" disabled>
          </div>
          <div class="form-group">
            <label for="season1StartDate" class="mb-0">Next scheduled date</label>
            <input value="{{event.nextOccurrenceTimestamp | date:'EEEE, MMM d yyyy @ HH:mm'}}" [disabled]="!editMode" name="seasonDate1"
                   placeHolder="Define the start date for the first season" class="form-control"/>
          </div>
          <div class="form-group">
            <label for="season1StartDate" class="mb-0">Season 1 start date</label>
            <input value="{{event.season1StartDate | date:'MMMM d'}}" [disabled]="!editMode" name="seasonDate1"
                   placeHolder="Define the start date for the first season" class="form-control"/>
          </div>
          <div *ngIf="!editMode" class="form-group">
            <label for="season2StartDate" class="mb-0">Season 2 start date</label>
            <input value="{{event.season2StartDate | date:'MMMM d'}}" [disabled]="!editMode" name="seasonDate2"
                   placeHolder="Define the start date for the second season" class="form-control"/>
          </div>
        </div>


      </div>
      <!-- end of second row - main form elements -->
    </div>
    
    <google-map *ngIf="!editMode" [options]="mapOptions" height="350px" width="100%">
      <map-marker #marker="mapMarker" (mapClick)="openInfoWindow(marker, 0)"  [position]="event.playSpace.googleLatLng">
        <map-info-window>
          <ul class="list-unstyled text-md mb-0">
            <li class="mb-1"><strong class="font-weight-600">{{event.playSpace.name}}</strong></li>
             <li><span>{{event.playSpace.address}}, {{event.playSpace.city.name}}, {{event.playSpace.city.country.name}}</span></li>

            <li  class="my-1 mt-2"><span>Working hours: </span><strong>{{event.playSpace.workingHours}}</strong></li>
            <li class="my-1"><span>Phone: </span><strong>{{event.playSpace.phone}}</strong></li>
            <li class="my-1 mb-2"><span>Web: </span><a target="_blank" href="{{event.playSpace.webAddress}}"><strong>{{event.playSpace.webAddress}}</strong></a><li>
          </ul>
        </map-info-window>
      </map-marker>
    </google-map>

    <!-- <agm-map *ngIf="!editMode"
                   [latitude]="mapLatitude"
                   [longitude]="mapLongitude"
                   [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
                   [style.height.px]="350">
            <agm-marker [latitude]="event.playSpace.latitude" [longitude]="event.playSpace.longitude" [markerDraggable]="false">
               <agm-info-window>
                <ul class="list-unstyled text-md mb-0">
                  <li class="mb-1"><strong class="font-weight-600">{{event.playSpace.name}}</strong></li>
                   <li><span>{{event.playSpace.address}}, {{event.playSpace.city.name}}, {{event.playSpace.city.country.name}}</span></li>

                  <li  class="my-1 mt-2"><span>Working hours: </span><strong>{{event.playSpace.workingHours}}</strong></li>
                  <li class="my-1"><span>Phone: </span><strong>{{event.playSpace.phone}}</strong></li>
                  <li class="my-1 mb-2"><span>Web: </span><a target="_blank" href="https://{{event.playSpace.webAddress}}"><strong>{{event.playSpace.webAddress}}</strong></a><li>

                  <li class="mt-2"><strong class="font-weight-500"><a href="" (click)="choosePlayLocation(m); $event.preventDefault()">Select this location<i  class="ml-2 fas fa-angle-right align-baseline "></i></a></strong></li>
                </ul>
              </agm-info-window>
            </agm-marker>
          </agm-map> -->
        <br>
    <!-- third row - rulebook and seasons -->
    <div class="form-group col-xs-12">
      <!-- rulebook -->
      <div *ngIf="!editMode || (editMode && event.active)" class="">
        
        <label for="rulebook">Rulebook</label>
        <textarea [(ngModel)]="event.rulebook" id="rulebook" name="rulebook" [disabled]="!editMode || !event.active"
                  placeHolder="Define the rules of the game for your team" class="form-control"
                  [rows]="editMode ? 15 : (event.rulebook?.length < 500 ? 5 : 15)">
        </textarea>
      </div>

      <div class="row justify-content-center align-items-center mt-4">
        <!-- season 1 -->
        <div class="col-auto mx-auto">
          <div *ngIf="editMode && event.active" class="form-group">
            <label for="season1StartDate">Season 1 start date</label>

            <div class="col-sm-pull-0 col-xs-pull-2">
              <div style="display:inline-block; min-height:150px;">
                <bs-datepicker-inline [(bsValue)]="event.season1StartDate" name="seasonDate1" id="season1StartDate" [showWeeks]="false" class="d-picker d-inline-block ml-auto" [bsConfig]="bsConfig"></bs-datepicker-inline>
                <!-- <datepicker [(ngModel)]="event.season1StartDate" name="seasonDate1" id="season1StartDate" [showWeeks]="false" class="d-picker"></datepicker> -->
              </div>
            </div>
          </div>
        </div>

        <!-- season 2 -->
        <div class="col-auto mx-auto">
          <div *ngIf="editMode && event.active" class="form-group">
            <label for="season2StartDate">Season 2 start date</label>
            <div class="col-sm-pull-0 col-xs-pull-2">
              <div style="display:inline-block; min-height:150px;">
                <bs-datepicker-inline [(bsValue)]="event.season2StartDate" name="seasonDate2" id="season2StartDate" [showWeeks]="false" class="d-picker d-inline-block ml-auto" [bsConfig]="bsConfig"></bs-datepicker-inline>
                <!-- <datepicker [(ngModel)]="event.season2StartDate" name="seasonDate2" id="season2StartDate" [showWeeks]="false" class="d-picker"></datepicker> -->
              </div>
            </div>
          </div>
        </div>
      </div><!-- .seasons-->
    </div>

    <!-- last row - Save/Cancel -->
    <div class="my-4 d-flex" *ngIf="editMode">
      <div class="pt-4 border-top flex-grow-1">
        <div *ngIf="!loading" class="row justify-content-center">
          <!-- Delete -->
          <div class="col-12 col-sm text-center text-sm-left mb-5 mb-sm-0" *ngIf="event.active && event.id">
            <button type="button" (click)="setEventForDelete(event); showDeleteDialog = !showDeleteDialog" class="btn btn-danger  px-4">Delete match</button>
            <app-dialog [(visible)]="showDeleteDialog">
              <div class="modal-header">
                <h5 class="mb-0">Are you sure you want to delete selected match?</h5>
              </div>
              <div class="modal-footer justify-content-start">
                <button type="button" class="btn btn-primary text-white btn-sm px-3" (click)="removeEvent(); showDeleteDialog = false">Yes</button>
                <button type="button" class="btn btn-secondary btn-sm px-3" (click)="showDeleteDialog = false">No</button>
              </div>
            </app-dialog>
          </div>
          <div *ngIf="event.viewedByManager" class="col-auto">
            <button type="button" class="btn btn-secondary px-4 text-white" (click)="cancelEventDetailsChange()">Cancel</button>
          </div>
          <div *ngIf="event.viewedByManager" class="col-auto">
            <button type="submit" class="btn btn-primary px-4" [disabled]="!eventForm.form.valid">Save</button>
          </div>
        </div>
        <div class="col d-flex justify-content-center" *ngIf="loading && event.viewedByManager">
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <img src='../../../assets/images/nogoshLoading0.08.gif' alt='[]'/> -->
        </div>
      </div>
    </div>

  </form>
</div>
